import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Document } from '@contentful/rich-text-types'
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text'
import { Asset } from 'contentful'

export interface ContentfulMedia {
  fluid?: IGatsbyImageData
  fixed?: IGatsbyImageData
  gatsbyImageData?: IGatsbyImageData
}

export interface BlockElement {
  id: string
  title: string
  internal: {
    type: 'ContentfulBlockElements'
  }
  backgroundColor: 'Yellow' | 'Pink' | 'Blue' | 'Lightgrey'
  body: RenderRichTextData<any>
  featuredMedia: {
    file: {
      url: string
    }
  }
}

export interface ContentfulSubpagesBoxItem {
  title: string
  page: ContentfulPageReference
  image: {
    file: {
      url: string
    }
  }
  backgroundImage: {
    file: {
      url: string
    }
  }
  textColor: string
  active: boolean
}

export interface ContentfulPageReference {
  id: string
  slug: string
  title: string
  excerpt: {
    excerpt: string
  }
  featuredImage: ContentfulMedia
  parentPage?: {
    slug: string
    parentPage?: {
      slug: string
    }
  }
}

export interface ContentfulPartnerItems {
  link: string
  category: string
  media: {
    file: {
      url: string
    }
  }
}

// An reference each hold a __typename that is automatically included, see the react inspector in certain blocks
export interface Reference
  extends BlockElement,
    ContentfulPageReference,
    ContentfulCoworker,
    ContentfulSubpagesBoxItem,
    ContentfulPartnerItems {
  __typename:
    | 'ContentfulCoworker'
    | 'ContentfulBlockElements'
    | 'ContentfulPage'
    | 'ContentfulSubpagesBoxItem'
    | 'ContentfulPartnerItems'
}

export interface ContentfulCoordinates {
  lat: number
  lon: number
}

export enum CaseBlockTypes {
  CaseImageGallery = 'ContentfulCaseImageGallery',
  CaseImageSingleText = 'ContentfulCaseImageRightSingleTextfield',
  CaseImageDoubleText = 'ContentfulCaseImageLeftDoubleTextfields',
  CaseAbout = 'ContentfulCaseAbout',
  CaseHero = 'ContentfulCaseHero',
  CaseNormalText = 'ContentfulCaseNormalText',
  CaseImageFullScreen = 'ContentfulCaseImageFullscreen',
  CaseHeroMain = 'ContentfulCaseHeroMain',
  CaseSummary = 'CaseSummary', // This one is programatically added
}

export enum BlockTypes {
  Divider = 'ContentfulBlockDivider',
  ProjectTeamBlock = 'ContentfulBlockProjectTeam',
  BlockTextBoxes = 'ContentfulBlockTextBoxes',
  Keyfigures = 'ContentfulKeyfigures',
  LatestBlogPost = 'Blog - Latest Article',
  LatestBlogPosts = 'ContentfulBlockLatestBlogPosts',
  Hero = 'Hero',
  ImageBoxLeftAligned = 'Image Box - Left',
  ImageBoxRightAligned = 'Image Box - Right',
  ElementsCentered = 'Elements - Centered',
  ElementsTwoColumns = 'Elements - Two columns',
  Quote = 'Quote',
  QuoteList = 'QuoteList',
  ContentReferences = 'Content References',
  JobAds = 'Job Ads - List',
  TextBoxes = 'Text Boxes',
  GoogleMap = 'Map',
  Coworkers = 'Coworkers',
  Text = 'Text - Normal',
  Logotypes = 'Logotypes',
  SubPageBoxes = 'Subpage boxes',
  HtmlCode = 'ContentfulHtmlCode',
}

//Case - Blocks

export interface DividerBlock {
  __typename: BlockTypes.Divider
}

export interface CaseHero {
  __typename: CaseBlockTypes.CaseHero
  backgroundColor?: 'Yellow' | 'Pink' | 'Blue' | 'Dark blue' | 'Light grey'
  headerLeft: string
  id: string
  client: string
  areaOfWork: string
  body: {
    raw: Document
  }
  fullScreenHero?: boolean
  heroImage: ContentfulMedia
}
export interface CaseHeroMain {
  __typename: CaseBlockTypes.CaseHeroMain
  backgroundColor?: 'Yellow' | 'Pink' | 'Blue' | 'Dark blue' | 'Light grey'
  id: string
  title: string
  content: {
    raw: Document
  }
}
export interface CaseNormalText {
  __typename: CaseBlockTypes.CaseNormalText
  id: string
  title: string
  content: {
    raw: Document
  }
}

export interface CaseImageGallery {
  __typename: CaseBlockTypes.CaseImageGallery
  id: string
  imageLeftGallery: ContentfulMedia
  imageGalleryRightTop: ContentfulMedia
  imageGalleryRightBottom: ContentfulMedia
}

export interface CaseImageDoubleText {
  __typename: CaseBlockTypes.CaseImageDoubleText
  id: string
  imageLeft: ContentfulMedia
  topContent: {
    raw: Document
  }
  bottomContent: {
    raw: Document
  }
}

export interface CaseImageSingleText {
  __typename: CaseBlockTypes.CaseImageSingleText
  id: string
  imageRight: ContentfulMedia
  imageSingleTextContent: {
    raw: Document
  }
}

export interface CaseAbout {
  __typename: CaseBlockTypes.CaseAbout
  id: string
  background?: 'Yellow' | 'Pink' | 'Blue' | 'Dark blue'
  references?: Reference[]
}

export interface CaseImageFullScreen {
  __typename: CaseBlockTypes.CaseImageFullScreen
  id: string
  imageFullscreen: ContentfulMedia
}

export interface CaseSummary {
  __typename: CaseBlockTypes.CaseSummary
  id: string
  client?: string
  tasks?: string[]
  tools?: string[]
  methods?: string[]
  date?: string
}

export interface BlockKeyfigures {
  __typename: BlockTypes.Keyfigures
  id: string
  title: string
  contentKeyFigures: Reference[]
}

export interface BlockTextBoxes {
  __typename: BlockTypes.BlockTextBoxes
  id: string
  title: string
  textBoxes: Reference[]
}

export interface NormalBlock {
  __typename: 'ContentfulBlock'
  id: string
  type: BlockTypes
  title: string
  coordinates?: ContentfulCoordinates
  media?: ContentfulMedia[]
  references?: Reference[]
  background?: 'Yellow' | 'Pink' | 'Blue' | 'Dark blue' | 'Light grey'
  fullScreenHero?: boolean
  content: {
    raw: Document
  }
  blogCategory?: 'Marknadsföring' | 'Webb' | 'Design'
}

export interface ProjectTeamBlock {
  __typename: BlockTypes.ProjectTeamBlock
  id: string
  title: string
  team: {
    raw: Document
  }
}

export interface BlockLatestBlockPosts {
  __typename: BlockTypes.LatestBlogPosts
  id: string
  title: string
  category?: 'Marknadsföring' | 'Webb' | 'Design' | 'All'
}

export interface HTMLCode {
  __typename: BlockTypes.HtmlCode
  id: string
  name: string
  code: { code: string }
}

export type Block =
  | NormalBlock
  | BlockLatestBlockPosts
  | BlockKeyfigures
  | CaseHero
  | CaseAbout
  | CaseImageFullScreen
  | CaseNormalText
  | CaseImageSingleText
  | CaseImageDoubleText
  | CaseImageGallery
  | BlockTextBoxes
  | ProjectTeamBlock
  | CaseHeroMain
  | DividerBlock
  | HTMLCode
  | CaseSummary

export enum BackgroundColors {
  'Yellow' = '#fffab2',
  'Pink' = '#dfe2ff',
  'Blue' = '#e5f6f7',
  'Dark blue' = '#313341',
  'Light grey' = '#f8f6f6',
}

export interface ContentfulBlogPost {
  title: string
  slug: string
  createdAt: string // given by contentful automatically
  updatedAt: string // given by contentful automatically
  publishedAt: string
  categories: string[]
  content: {
    raw: Document
  }
  excerpt: {
    excerpt: string
  }
  featuredMedia: ContentfulMedia
  author?: ContentfulCoworker
}

export type ClientContentfulBlogPost = Omit<
  ContentfulBlogPost,
  'featuredMedia'
> & {
  featuredMedia: Asset
}

type PageTemplate = 'Normal' | 'Career' | 'Blog'

export interface ContentfulPage {
  title: string
  slug: string
  excerpt: {
    excerpt: string
  }
  blockIndexing: boolean | null
  blocks: Block[]
  template: PageTemplate
  seoTitle?: string
  parentPage?: {
    slug: string
    parentPage?: {
      slug: string
    }
  }
  theme: 'Dark' | 'Light'
}

export interface ContentfulCoworker {
  id: string
  fullName: string
  avatar: ContentfulMedia
  workTitle: string
  phoneNumber: string
  email: string
  department: 'Sales' | 'Development' | 'Project Management' | 'Experience'
}

type CaseCategories = 'Marknadsföring' | 'Webb' | 'Design' | 'All'

export interface ContentfulCase {
  featuredMedia: ContentfulMedia
  title: string
  slug: string
  heading: string
  excerpt: {
    excerpt: string
  }
  subtitle?: string
  client: string
  tasks?: string[]
  tools?: string[]
  methods?: string[]
  projectDate: string
  category: CaseCategories
  blocks?: Block[]
}
