import { ContentfulPage } from 'types/contentful'

export function convertPageTreeToUrl<
  T extends Pick<ContentfulPage, 'slug' | 'parentPage'>
>(reference: T, slugs: string[] = []): string {
  if (reference.slug) {
    slugs.push(reference.slug)
  }
  if (reference.parentPage) {
    convertPageTreeToUrl(reference.parentPage, slugs)
  }
  return slugs.reverse().join('/')
}
