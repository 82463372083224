/* eslint-disable prettier/prettier */
import React from 'react'
import { map, has, filter, get } from 'lodash/fp'

// Components
import { createMarkupFromDocument } from 'components/RichText'

// Blocks
import { Hero } from './Hero/Hero'
import { Quote } from './Quote/Quote'
import { QuoteList } from './QuoteList/QuoteList'
import { ImageBox } from './ImageBox/ImageBox'
import { ElementsCentered } from './Elements/ElementsCentered'
import { ElementsTwoColumns } from './Elements/ElementsTwoColumns'
import { BlogLatestArticle } from './Blog/BlogLatestArticle'
import { BlogThreeLatestArticles } from './Blog/BlogThreeLatestArticles'
import { ContentReferences } from './ContentReferences/ContentReferences'
import { JobAds } from './JobAds'
import { TextBoxes } from './TextBoxes'
import { Map } from './Map/Map'
import { Coworkers } from './Coworker'
import { TextNormal } from './Text'
import { SubpageBoxesModule } from './SubpageBoxes/SubpageBoxes'
import { Logotypes } from './Logotypes'
import { KeyFigures } from './KeyFigures/KeyFigures'
import ProjectTeam from './Team/Team'
import Divider from './Divider'
import { HTMLCode } from './HTMLCode/HTMLCode'
import { Partners } from './Partners/Partners'

//CaseBlocks
import { CaseAboutBlock } from './Case/About/CaseAbout'
import { CaseHeroBlock } from './Case/Hero/CaseHero'
import { CaseImageFull } from './Case/ImageFullScreen/CaseImageFullScreen'
import { ImageSingleText } from './Case/SingleTextWithImage/SingeTextWithImage'
import { ImageDoubleText } from './Case/DoubleTextWithImage/DoubleTextWithImage'
import { ImageGallery } from './Case/ImageGallery/ImageGallery'
import CaseSummary from './Case/Summary/CaseSummary'

// Types
import {
  Block,
  BlockTypes,
  BackgroundColors,
  ContentfulCoworker,
  CaseBlockTypes,
} from 'types/contentful'

// Styling
import './blocks.scss'

interface Props {
  blocks: Block[]
}

export const Blocks: React.FC<Props> = ({ blocks }) => {
  const blockComponents = map((block) => {
    if (block.__typename === BlockTypes.LatestBlogPosts) {
      return (
        <BlogThreeLatestArticles
          key={block.id}
          title={block.title}
          category={block.category}
        />
      )
    }

    if (block.__typename === BlockTypes.HtmlCode) {
      return (
        <HTMLCode key={block.id} name={block.name} code={block.code.code} />
      )
    }

    if (block.__typename === BlockTypes.Divider)
      return <Divider key="Divider" />

    if (block.__typename === CaseBlockTypes.CaseImageGallery)
      return <ImageGallery key={block.id} block={block}></ImageGallery>

    if (block.__typename === CaseBlockTypes.CaseImageFullScreen)
      return <CaseImageFull key={block.id} block={block}></CaseImageFull>

    if (block.__typename === CaseBlockTypes.CaseImageSingleText)
      return <ImageSingleText key={block.id} block={block}></ImageSingleText>

    if (block.__typename === CaseBlockTypes.CaseImageDoubleText)
      return <ImageDoubleText key={block.id} block={block}></ImageDoubleText>

    if (block.__typename === CaseBlockTypes.CaseHero)
      return <CaseHeroBlock key={block.id} block={block}></CaseHeroBlock>

    if (block.__typename === BlockTypes.Keyfigures)
      return <KeyFigures key={block.id} block={block}></KeyFigures>

    if (block.__typename === BlockTypes.BlockTextBoxes)
      return <TextBoxes key={block.id} references={block.textBoxes}></TextBoxes>

    if (block.__typename === BlockTypes.ProjectTeamBlock)
      return <ProjectTeam key={block.id} block={block}></ProjectTeam>

    if (block.__typename === CaseBlockTypes.CaseAbout) {
      const color = block.background && BackgroundColors[block.background]
      return (
        <CaseAboutBlock
          key={block.id}
          background={color}
          block={block}
        ></CaseAboutBlock>
      )
    }

    if (block.__typename === CaseBlockTypes.CaseSummary) {
      return (
        <CaseSummary
          key={block.id}
          client={block.client}
          date={block.date}
          methods={block.methods}
          tasks={block.tasks}
          tools={block.tools}
        />
      )
    }

    if (block.__typename === CaseBlockTypes.CaseNormalText) {
      const caseText = block.content
        ? createMarkupFromDocument(block.content)
        : null
      return (
        <TextNormal title={block.title} key={block.id}>
          {caseText}
        </TextNormal>
      )
    }

    const text = block.content ? createMarkupFromDocument(block.content) : null
    const color = block.background && BackgroundColors[block.background]

    const fullscreenHero = has('fullScreenHero', block)
      ? get('fullScreenHero', block)
      : false

    switch (block.type) {
      case BlockTypes.Hero:
        return (
          <Hero
            key={block.id}
            title={block.title}
            image={get('media[0].gatsbyImageData', block)}
            background={color}
            isFullScreenHero={fullscreenHero}
          >
            {text}
          </Hero>
        )
      case 'Image Box - Left':
        return (
          <ImageBox
            key={block.id}
            title={block.title}
            images={get('media', block)}
            background={color}
          >
            {text}
          </ImageBox>
        )
      case 'Elements - Centered':
        return (
          <ElementsCentered
            key={block.id}
            elements={block.references}
            background={color}
          />
        )

      case 'Blog - Latest Article':
        return <BlogLatestArticle key={block.id} background={color} />

      case 'Quote':
        return (
          <Quote key={block.id} background={color}>
            {text}
          </Quote>
        )

      case 'QuoteList':
        return (
          <QuoteList
            key={block.id}
            background={color}
            references={block.references}
          />
        )

      case 'Content References':
        return (
          <ContentReferences
            key={block.id}
            background={color}
            references={block.references}
          />
        )

      case 'Job Ads - List':
        return (
          <JobAds key={block.id} title={block.title} background={color}>
            {text}
          </JobAds>
        )

      case 'Text Boxes':
        return <TextBoxes key={block.id} references={block.references} />

      case 'Map':
        return (
          <Map
            key={block.id}
            title={block.title}
            coordinates={block.coordinates}
          >
            {text}
          </Map>
        )

      case 'Coworkers': {
        const coworkers: ContentfulCoworker[] = filter(
          (ref) => ref['__typename'] === 'ContentfulCoworker',
          block.references
        )

        return (
          <Coworkers
            key={block.id}
            title={block.title}
            background={color}
            coworkers={coworkers}
          >
            {text}
          </Coworkers>
        )
      }

      case 'Text - Normal':
        return (
          <TextNormal key={block.id} title={block.title}>
            {text}
          </TextNormal>
        )

      case 'Logotypes':
        return (
          <Logotypes
            key={block.id}
            title={block.title}
            logotypes={block.media}
          />
        )
      case 'Subpage boxes':
        return (
          <SubpageBoxesModule
            key={block.id}
            references={block.references}
            title={block.title}
            background={color}
            excerpt={text}
          />
        )

      case 'Elements - Two columns':
        return (
          <ElementsTwoColumns
            key={block.id}
            elements={block.references}
            background={color}
            title={block.title}
          >
            {text}
          </ElementsTwoColumns>
        )
      case 'Partners':
        return <Partners key={block.id} elements={block.references} />

      default:
        console.warn(
          `Detected a block type of "${block.type}" that is missing a correspondig component`
        )
        return null
    }
  }, blocks)

  return <div className="block-container">{blockComponents}</div>
}
