import React from 'react'

//styles
import './team.scss'

//components
import { ProjectTeamBlock } from 'types/contentful'
import { createMarkupFromDocument } from 'components/RichText'

interface Props {
  block: ProjectTeamBlock
}

export const ProjectTeam: React.FC<Props> = ({ block }) => {
  const body = createMarkupFromDocument(block.team)

  return (
    <div className="projectTeam">
      <div className="projectTeam__content">{body}</div>
    </div>
  )
}

export default ProjectTeam
