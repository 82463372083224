interface Config {
  hex: string
  alpha?: number
}

/**
 * Convert a six digit hex code to rgba
 *
 * @param {string} Hex The hex code to convert from
 * @param {number} Alpha Optional alpha e.g. 0.5
 * @return {string | undefined} the rgba string or undefined
 */
export const convertHexToRGBA = ({
  hex,
  alpha = 1,
}: Config): string | undefined => {
  const regex = hex.match(/\w\w/g)

  if (regex) {
    const [r, g, b] = regex.map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  return undefined
}
