import React from 'react'

import './text-normal.scss'

interface Props {
  title: string
  children: React.ReactNode | React.ReactNode[]
}

export const TextNormal: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="block block--text-normal text-normal header-dark">
      <div className={'text-normal__inner'}>
        <h3 className={'text-normal__title'}>{title}</h3>
        <div className="text-normal__content standard-article">{children}</div>
      </div>
    </div>
  )
}
