import React from 'react'

// Styling
import './map.scss'

// Components
import { GoogleMap } from '../../GoogleMap'

// Types
import { ContentfulCoordinates } from 'types/contentful'
import { Blob } from 'components/Blob'

interface Props {
  title: string
  children: React.ReactNode[] | React.ReactNode
  coordinates?: ContentfulCoordinates
}

export const Map: React.FC<Props> = ({ title, children, coordinates }) => {
  const loadingElement = <div />
  const containerElement = <div className="map__map-container" />
  const mapElement = <div className="map__map-element" />
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GOOGLE_MAPS_API_KEY}`

  if (!coordinates?.lat || !coordinates?.lon) {
    return null
  }

  return (
    <div className={'block block--map map header-dark'}>
      <div className="map__left-column">
        <div className="map__content">
          <h3 className="map__title">{title}</h3>
          <div className="map__text">{children}</div>
          <div className="map__blob">
            <Blob which={'B'} color="Blue" />
          </div>
        </div>
      </div>
      <div className="map__right-column">
        <GoogleMap
          googleMapURL={googleMapURL}
          lat={coordinates.lat}
          lon={coordinates.lon}
          showMarker={true}
          zoom={14}
          markerPosition={coordinates}
          containerElement={containerElement}
          mapElement={mapElement}
          loadingElement={loadingElement}
        />
      </div>
    </div>
  )
}
