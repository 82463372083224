import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import './image-box.scss'
import { BackgroundColors, ContentfulMedia } from 'types/contentful'

interface Props {
  title: string
  images?: ContentfulMedia[]
  children: React.ReactNode | React.ReactNode[]
  background: BackgroundColors | undefined
}

export const ImageBox: React.FC<Props> = ({
  title,
  images,
  children,
  background,
}) => {
  const style = {
    backgroundColor: background,
  }

  return (
    <div
      className={'block block--image-box image-box header-dark'}
      style={style}
    >
      <div className={'image-box__media'}>
        <div className={'image-box__primary-image'}>
          {images && images[0]?.gatsbyImageData && (
            <GatsbyImage image={images[0].gatsbyImageData} alt="" />
          )}
        </div>
        {images && images[1]?.gatsbyImageData && (
          <GatsbyImage
            image={images[1]?.gatsbyImageData}
            className="image-box__secondary-image"
            alt=""
          />
        )}
      </div>
      <div className={'image-box__content'}>
        <h2 className={'image-box__title'}>{title}</h2>
        <div className={'image-box__text'}>{children}</div>
      </div>
    </div>
  )
}
