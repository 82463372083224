/* eslint-disable prettier/prettier */
import React from 'react'
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { isEmpty, map, get } from 'lodash/fp'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// Types
import { BackgroundColors, BlockElement, Reference } from 'types/contentful'
import { createMarkupFromDocument } from 'components/RichText'

// Components
import { QuoteIcon } from './Icon'

// Styling
import './quote-list.scss'

interface Props {
  references?: Reference[]
  background: BackgroundColors | undefined
}

export const QuoteList: React.FC<Props> = ({ references, background }) => {
  if (isEmpty(references)) {
    return null
  }

  const style = {
    backgroundColor: background,
  }

  const textStyle =
    background == '#313341'
      ? 'block block--quote quote quote--light'
      : 'block block--quote quote'

  const iconStyle = background == '#313341' ? '#ffffff' : '#3B8181'

  return (
    <Swiper
      modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false
      }}
      spaceBetween={-1}
      slidesPerView={1}
      centeredSlides={true}
      centeredSlidesBounds={true}
      navigation={true}
      pagination={true}
      style={style}
    >

      {map((ref: BlockElement) => {
        const text = ref.body
        ? createMarkupFromDocument(ref.body)
        : null
        return(
          <SwiperSlide key={ref.id}>
            <div className={textStyle}>
              <QuoteIcon color={iconStyle} className={'quote__icon'} />
              {text && <div>{text}</div>}
            </div>
          </SwiperSlide>
        )
      }, references)}
    </Swiper>
  )
}
