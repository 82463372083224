import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

//types
import { CaseImageGallery } from 'types/contentful'

//styles
import './image-gallery.scss'

interface Props {
  block: CaseImageGallery
}

export const ImageGallery: FC<Props> = ({ block }) => {
  return (
    <div className="imageGallery">
      {block?.imageLeftGallery?.gatsbyImageData && (
        <GatsbyImage
          image={block.imageLeftGallery.gatsbyImageData}
          className="imageGallery__imageLeft"
          alt=""
        />
      )}
      {block.imageGalleryRightTop.gatsbyImageData && (
        <GatsbyImage
          image={block.imageGalleryRightTop.gatsbyImageData}
          className="imageGallery__imageRightTop"
          alt=""
        />
      )}
      {block.imageGalleryRightBottom.gatsbyImageData && (
        <GatsbyImage
          image={block.imageGalleryRightBottom.gatsbyImageData}
          className="imageGallery__imageRightBottom"
          alt=""
        />
      )}
    </div>
  )
}
