import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

//comonents
import { CaseImageSingleText } from 'types/contentful'
import { createMarkupFromDocument } from 'components/RichText'

//styles
import './single-text-with-image.scss'

interface Props {
  block: CaseImageSingleText
}

export const ImageSingleText: React.FC<Props> = ({ block }) => {
  const text = createMarkupFromDocument(block.imageSingleTextContent)
  return (
    <div className="imageSingleText">
      {block.imageRight.gatsbyImageData && (
        <GatsbyImage
          image={block.imageRight.gatsbyImageData}
          className="imageSingleText__image"
          alt=""
        />
      )}
      <div className="imageSingleText__content">{text}</div>
    </div>
  )
}
