import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

//components
import { CaseImageDoubleText } from 'types/contentful'
import { createMarkupFromDocument } from 'components/RichText'

//styles
import './double-text-with-image.scss'

interface Props {
  block: CaseImageDoubleText
}

export const ImageDoubleText: React.FC<Props> = ({ block }) => {
  const toptext = createMarkupFromDocument(block.topContent)
  const bottomText = createMarkupFromDocument(block.bottomContent)

  return (
    <div className="imageDoubleText">
      {block.imageLeft.gatsbyImageData && (
        <GatsbyImage
          image={block.imageLeft.gatsbyImageData}
          className="imageDoubleText__image"
          alt=""
        />
      )}
      <div className="imageDoubleText__topContent">{toptext}</div>
      <div className="imageDoubleText__bottomContent">{bottomText}</div>
    </div>
  )
}
