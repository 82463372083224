import React from 'react'
import { CaseAbout } from 'types/contentful'

// Styling
import './about-case.scss'

// Components
import { createMarkupFromDocument } from 'components/RichText'

interface Props {
  block: CaseAbout
}

export const CaseAboutBlock: React.FC<Props> = ({ block }) => {
  if (!block) return null

  return (
    <div className="about-case">
      <div className="about-case__inner">
        {block.references?.map((ref) => {
          const text = createMarkupFromDocument(ref.body)
          return (
            <div key={ref.id + ref.title} className="about-case__item">
              <h3 className="about-case__item-title">{ref.title}</h3>
              <div className="about-case__item-body">{text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
