import React from 'react'
import { get } from 'lodash/fp'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

// Types
import { BackgroundColors } from 'types/contentful'

// Hooks
import { useLatestBlogPostQuery } from 'hooks/use-latest-blogpost'

// Utils
import { localizeDate } from 'utils/dates'

// Styling
import './blog-latest-article.scss'

interface Props {
  background: BackgroundColors | undefined
}

export const BlogLatestArticle: React.FC<Props> = ({ background }) => {
  const blogPost = useLatestBlogPostQuery()

  if (!blogPost) {
    return null
  }

  const style = {
    backgroundColor: background,
  }

  const date = localizeDate(blogPost.publishedAt)

  return (
    <div
      className="block block--blog-article blog-article header-light"
      style={style}
    >
      <div className="blog-article__content">
        <Link
          to={`/tips-och-trender/${blogPost.slug}`}
          className={'blog-article__link-title'}
          title={`Läs mer om ${blogPost.title}`}
        >
          <h3 className="blog-article__title">{blogPost.title}</h3>
        </Link>
        <time dateTime={blogPost.createdAt} className={'blog-article__time'}>
          {date}
        </time>
        <p className="blog-article__text">{get('excerpt.excerpt', blogPost)}</p>
        <Link
          to={`/tips-och-trender/${blogPost.slug}`}
          title={`Läs mer om ${blogPost.title}`}
          className="blog-article__link"
        >
          Läs hela artikeln
        </Link>
      </div>

      {blogPost.featuredMedia.gatsbyImageData && (
        <div className="blog-article__media">
          <Link
            to={`/tips-och-trender/${blogPost.slug}`}
            title={`Läs mer om ${blogPost.title}`}
          >
            <GatsbyImage
              image={blogPost.featuredMedia?.gatsbyImageData}
              className="blog-article__image"
              alt=""
            />
          </Link>
        </div>
      )}
    </div>
  )
}
