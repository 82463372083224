import React from 'react'
import { map, isEmpty } from 'lodash/fp'
import classNames from 'classnames'

// Styling
import './elements-centered.scss'

// Components
import { createMarkupFromDocument } from 'components/RichText'
import { Blob } from 'components/Blob'

// Types
import { BackgroundColors, Reference, BlockElement } from 'types/contentful'

interface Props {
  elements?: Reference[]
  background: BackgroundColors | undefined
}

const WhichBlob = ['A', 'B', 'C']

export const ElementsCentered: React.FC<Props> = ({ elements, background }) => {
  if (isEmpty(elements)) {
    return null
  }

  const style = {
    backgroundColor: background,
  }

  let index = 0

  const classes = classNames('block block--ec ele-centered header-dark', {
    'ele-centered--one': elements?.length === 1,
    'ele-centered--two': elements?.length === 2,
    'ele-centered--three': elements?.length === 3,
  })

  return (
    <div className={classes} style={style}>
      <div className={'ele-centered__elements'}>
        {map((element: BlockElement) => {
          const which = WhichBlob[index] as 'A' | 'B' | 'C'
          const text = element.body
            ? createMarkupFromDocument(element.body)
            : null
          index++
          return (
            <div key={element.id} className={'ele-centered-element'}>
              <div className={'ele-centered-element__image'}>
                <img
                  className={'ele-centered-element__icon'}
                  src={element.featuredMedia.file.url}
                  alt="icon"
                />
                <Blob which={which} color={element.backgroundColor} />
              </div>
              <div className={'ele-centered-element__copy'}>
                <h3 className={'ele-centered-element__title'}>
                  {element.title}
                </h3>
                {text}
              </div>
            </div>
          )
        }, elements)}
      </div>
    </div>
  )
}
