import { get } from 'lodash/fp'
import { useStaticQuery, graphql } from 'gatsby'

import { ContentfulBlogPost } from 'types/contentful'

export const useLatestBlogPostQuery = (): ContentfulBlogPost | null => {
  const { allContentfulBlogPost } = useStaticQuery(graphql`
    query useLatestBlogPostQuery {
      allContentfulBlogPost(
        limit: 1
        sort: { fields: publishedAt, order: DESC }
      ) {
        edges {
          node {
            id
            title
            slug
            createdAt
            updatedAt
            publishedAt

            excerpt {
              excerpt
            }

            featuredMedia {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return get(['edges', 0, 'node'], allContentfulBlogPost)
}
