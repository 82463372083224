import React from 'react'
import { Link } from 'gatsby'

interface Props {
  title: string
  href?: string
  text?: string
  buttonText?: string
  prefixUrl?: string
}

export const JobAd: React.FC<Props> = ({
  title,
  href,
  text,
  buttonText = 'Läs mer om jobbet',
  prefixUrl = null,
}) => {
  const url = prefixUrl ? `/${prefixUrl}${href}` : `${href}`
  const isLinkInternal = /^\/(?!\/)/.test(url)
  return (
    <div className="card card--jobAd jobAd">
      {isLinkInternal ? (
        <Link
          to={url}
          title={`Läs om ${title}`}
          className={'jobAd__title-link'}
        >
          <h3 className="jobAd__title">{title}</h3>
        </Link>
      ) : (
        <h3 className="jobAd__title">{title}</h3>
      )}
      <p className="jobAd__pitch">{text}</p>
      {isLinkInternal ? (
        <Link to={url} title={`Läs om ${title}`} className="jobAd__link">
          {buttonText}
        </Link>
      ) : (
        <a href={url} title={`Läs om ${title}`} className="jobAd__link">
          {buttonText}
        </a>
      )}
    </div>
  )
}
