import React from 'react'

// Styling
import './html-code.scss'

interface Props {
  name: string
  code: string
}

export const HTMLCode: React.FC<Props> = ({ name, code }) => {
  return (
    <div
      className="block block--html"
      dangerouslySetInnerHTML={{
        __html: code,
      }}
    />
  )
}
