import React, { FC } from 'react'

import './case-summary.scss'

interface Props {
  client?: string
  tasks?: string[]
  tools?: string[]
  methods?: string[]
  date?: string
}

const CaseSummary: FC<Props> = ({ client, tasks, tools, methods, date }) => {
  return (
    <div className="summary">
      <div className="summary__inner">
        {client && (
          <div className="summary__item">
            <h4 className="summary__title">För vem: </h4>
            <span className="summary__body">{client}</span>
          </div>
        )}
        {tasks && (
          <div className="summary__item">
            <h4 className="summary__title">Vad: </h4>
            {tasks.map((task, index, arr) => (
              <span key={index} className="summary__body">
                {task}
                {index != arr.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        )}
        {tools && (
          <div className="summary__item">
            <h4 className="summary__title">Verktyg: </h4>
            {tools?.map((tool, index, arr) => (
              <span key={index} className="summary__body">
                {tool}
                {index != arr.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        )}
        {methods && (
          <div className="summary__item">
            <h4 className="summary__title">Metoder: </h4>
            {methods?.map((method, index, arr) => (
              <span key={index} className="summary__body">
                {method}
                {index != arr.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        )}
        {date && (
          <div className="summary__item">
            <h4 className="summary__title">När: </h4>
            <span className="summary__body">{date}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CaseSummary
