import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Interfaces
import { ContentfulMedia } from 'types/contentful'

// Styling
import './logotypes.scss'

interface Props {
  title: string
  logotypes?: ContentfulMedia[]
}

export const Logotypes: React.FC<Props> = ({ title, logotypes }) => {
  if (!logotypes) {
    return null
  }

  return (
    <div className={'block block--logotypes logotypes header-dark'}>
      <h2 className={'logotypes__title'}>{title}</h2>
      <div className={'logotypes__images'}>
        {logotypes.map((logo, index) => (
          <div key={index} className={'logotypes__image'}>
            {logo?.gatsbyImageData && (
              <GatsbyImage
                className={'logotypes__image-wrapper'}
                image={logo.gatsbyImageData}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
