import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { EntryCollection, Asset } from 'contentful'

// Components
import { Blob } from 'components/Blob'
import { BlogPost, BlogPosts } from 'components/BlogPosts'

// Types
import { ContentfulBlogPost } from 'types/contentful'

// Services
import { getBlogPosts, ContentfulQuery } from 'services/contentful'

// Styling
import './blog-latest-article.scss'
import './blog-three-latest-articles.scss'

interface Props {
  title: string
  category?: string
}

export const BlogThreeLatestArticles: React.FC<Props> = ({
  title,
  category = 'all',
}) => {
  const [blogPosts, setBlogPosts] =
    useState<EntryCollection<ContentfulBlogPost> | null>(null)

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const options: ContentfulQuery = {
        limit: 3,
      }

      // Add category if not equal to 'all' in which we case we don't want to filter on category
      if (category !== 'All') {
        options['fields.categories[in]'] = category
      }

      const blogPosts = await getBlogPosts(options)
      setBlogPosts(blogPosts)
    }
    fetchBlogPosts()
  }, [category])

  if (!blogPosts || blogPosts.total === 0) {
    return null
  }

  return (
    <div className="block blog-three-latest-articles">
      <h2 className="blog-posts-list__title">{title}</h2>
      <BlogPosts>
        {blogPosts.items.map((blogPost) => {
          const image = (blogPost.fields.featuredMedia as Asset).fields
          return (
            <BlogPost
              key={blogPost.sys.id}
              post={blogPost.fields}
              mediaType="only-src"
              prefixUrl={'tips-och-trender'}
              mediaSrc={image.file.url}
            />
          )
        })}
      </BlogPosts>
      <div className="blog-blob-container">
        <div className="blog-blob-container__blob">
          <Blob which={'C'} color={'Blue'} />
        </div>
        <Link to={`/tips-och-trender/`} className="blog-blob-container__link">
          {'Se alla våra tips & trender'}
        </Link>
      </div>
    </div>
  )
}
