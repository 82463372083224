import React from 'react'

interface Props {
  width?: number
  height?: number
  className?: string
  color?: string
}

export const QuoteIcon: React.FC<Props> = ({
  width,
  height,
  className,
  color,
}) => (
  <svg width={width} height={height} viewBox="0 0 109 88" className={className}>
    <path
      d="M24.595 88c12.856 0 22.359-10.65 22.359-22.7 0-12.612-6.987-22.701-21.241-22.701-7.267 0-13.975 3.083-18.446 8.407h-.56c.28-22.98 13.975-41.477 36.334-46.242V0C15.092 5.325 0 27.465 0 56.611 0 76.23 9.223 88 24.595 88zm62.046 0C99.497 88 109 77.35 109 65.3c0-12.612-6.987-22.701-21.241-22.701-7.267 0-13.974 3.083-18.446 8.407h-.56c.28-22.98 13.975-41.477 36.334-46.242V0c-27.949 5.325-43.04 27.465-43.04 56.611C62.046 76.23 71.268 88 86.64 88z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

QuoteIcon.defaultProps = {
  width: 109,
  height: 88,
  className: '',
}
