import React from 'react'

// Styling
import './key-figures.scss'

// Components
import { createMarkupFromDocument } from 'components/RichText'
import { BlockKeyfigures } from 'types/contentful'

interface Props {
  block: BlockKeyfigures
}

export const KeyFigures: React.FC<Props> = ({ block }) => {
  if (!block) return null

  return (
    <div className="keyfigure">
      <div className="keyfigure__rectangle">
        <div className="keyfigure__inner">
          {block.contentKeyFigures.map((ref) => {
            const text = createMarkupFromDocument(ref.body)
            return (
              <div key={ref.id} className="keyfigure__item">
                <h3>{ref.title}</h3>
                <div className="keyfigure__text">{text}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
