import React from 'react'

// Types
import { BackgroundColors } from 'types/contentful'

// Components
import { QuoteIcon } from './Icon'

// Styling
import './quote.scss'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  background: BackgroundColors | undefined
}

export const Quote: React.FC<Props> = ({ children, background }) => {
  const style = {
    backgroundColor: background,
  }

  const textStyle =
    background == '#313341'
      ? 'block block--quote quote quote--light'
      : 'block block--quote quote'

  const iconStyle = background == '#313341' ? '#ffffff' : '#3B8181'

  return (
    <div className={textStyle} style={style}>
      <QuoteIcon color={iconStyle} className={'quote__icon'} />
      {children}
    </div>
  )
}
