import React from 'react'
import { map, isEmpty, has } from 'lodash/fp'

// Components
import { createMarkupFromDocument } from 'components/RichText'

// Styling
import './text-boxes.scss'

// Types
import { Reference, BlockElement } from 'types/contentful'
import { Blob } from 'components/Blob'

interface Props {
  references?: Reference[]
}

type BlobType = 'A' | 'B' | 'C'

export const TextBoxes: React.FC<Props> = ({ references }) => {
  if (isEmpty(references)) {
    return null
  }

  return (
    <div className={'block block--textBoxes text-boxes'}>
      {map((element: BlockElement) => {
        const typesOfBlob = ['A', 'B', 'C']
        const defaultColor = 'Yellow'
        const randomNumber = Math.floor(Math.random() * typesOfBlob.length)
        const blobType = typesOfBlob[randomNumber] as BlobType
        const blobColor = !element.backgroundColor
          ? defaultColor
          : element.backgroundColor
        const text = element.body
          ? createMarkupFromDocument(element.body)
          : null
        return (
          <div key={element.id + element.title} className="text-boxes__box">
            <div className="text-boxes__blob">
              <Blob which={blobType} color={blobColor} />
            </div>
            <h3 className="text-boxes__title">{element.title}</h3>
            {text && <div className="text-boxes__content">{text}</div>}
          </div>
        )
      }, references)}
    </div>
  )
}
