import React from 'react'
import { map, isEmpty } from 'lodash/fp'

// Components
import { Coworker } from './Coworker'

// Styling
import './coworker-list.scss'

// types
import { BackgroundColors, ContentfulCoworker } from 'types/contentful'

interface Props {
  title?: string
  background: BackgroundColors | undefined
  coworkers?: ContentfulCoworker[]
  children: React.ReactNode | React.ReactNode[]
}

const clipPaths = [
  '#avatarClipPath-a',
  '#avatarClipPath-b',
  '#avatarClipPath-c',
  '#avatarClipPath-d',
  '#avatarClipPath-e',
  '#avatarClipPath-f',
]

let clipPathIndex = 0

export const Coworkers: React.FC<Props> = ({
  title,
  children,
  coworkers,
  background,
}) => {
  const listOfCoworkers =
    !isEmpty(coworkers) &&
    map((coworker) => {
      const clipPathOption = clipPathIndex % clipPaths.length
      const clipPath = clipPaths[clipPathOption]
      clipPathIndex++
      return (
        <Coworker key={coworker.id} coworker={coworker} clipPath={clipPath} />
      )
    }, coworkers)

  return (
    <div className="block block--coworkers coworkers header-dark">
      <header className={'coworkers__header'}>
        {title && <h3 className="coworkers__title">{title}</h3>}
        <div className="coworkers__content">{children}</div>
      </header>
      <div className="coworkers__list">{listOfCoworkers}</div>
      <svg width="0" height="0">
        <defs>
          <clipPath id="avatarClipPath-a">
            <path
              d="M88.5 0c63.592 15.636 105.293 37.317 88.5 88s-39.81 94.944-88.5 88S27.691 128.785 0 88 24.908-15.636 88.5 0z"
              transform="translate(7.565 2.447)"
            ></path>
          </clipPath>
          <clipPath id="avatarClipPath-b">
            <path
              d="M88 0c51.448 18.664 100.984 39.83 88 88s-41.427 63.009-88 88S0 136.6 0 88 36.552-18.664 88 0z"
              transform="translate(0 3.578)"
            />
          </clipPath>
          <clipPath id="avatarClipPath-c">
            <path
              d="M88 7.263c54.949-19.327 90.652 47.891 83.756 89.05S146.3 174.76 88 172.958 0 136.6 0 88 33.051 26.59 88 7.263z"
              transform="translate(0 -3.849)"
            />
          </clipPath>
          <clipPath id="avatarClipPath-d">
            <path
              d="M88.5 0c53.557-15.427 80.454 36.628 88.5 88s-51.015 87.839-88.5 88S-11.2 138.9 0 88 34.943 15.427 88.5 0z"
              transform="translate(1.329 2.757)"
            />
          </clipPath>
          <clipPath id="avatarClipPath-e">
            <path
              d="M88.5 0c52.821-6.747 91.071 21.188 88.5 88s-39.536 65.234-88.5 88S-15.871 136.1 0 88 35.679 6.747 88.5 0z"
              transform="translate(2.769 .981)"
            />
          </clipPath>
          <clipPath id="avatarClipPath-f">
            <path
              d="M88 0C33.7 23.9 0 39.4 0 88a88 88 0 0 0 176 0c0-48.6-33.7-111.9-88-88z"
              transform="translate(0 5.213)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
