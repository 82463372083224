import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { BackgroundColors, ContentfulMedia } from 'types/contentful'

import './hero.scss'

interface Props {
  title: string
  children: React.ReactNode | React.ReactNode[]
  image?: IGatsbyImageData
  background: BackgroundColors | undefined
  isFullScreenHero: boolean | undefined
}

export const Hero: React.FC<Props> = ({
  title,
  children,
  image,
  isFullScreenHero,
  background,
}) => {
  const style = {
    backgroundColor: background,
  }

  const titleStyle =
    background == '#313341' ? 'hero__title hero--white' : 'hero__title'
  const textStyle =
    background == '#313341' ? 'hero__text hero--white' : 'hero__text'
  const headerStyle =
    background == '#313341'
      ? 'block block--hero hero header-light'
      : 'block block--hero hero header-dark'
  const fullScreenHero = !isFullScreenHero ? 'hero__image' : 'hero__image-full'
  return (
    <div className={headerStyle} style={style}>
      {image && <GatsbyImage image={image} className={fullScreenHero} alt="" />}
      <div className={'hero__content'}>
        <h1 className={titleStyle}>{title}</h1>
        <div className={textStyle}>{children}</div>
      </div>
    </div>
  )
}
