import React from 'react'
import { GoogleMap as Map, LoadScript, Marker } from '@react-google-maps/api'

interface Props {
  lat: number
  lon: number
  zoom?: number
  showMarker?: boolean
  markerPosition?: {
    lat: number
    lon: number
  }
}

const containerStyle = {
  width: '100%',
  height: '400px',
}

export const GoogleMap: React.FC<Props> = ({
  lat,
  lon,
  zoom = 8,
  showMarker = false,
  markerPosition,
}) => {
  const GoogleMapsOptions: google.maps.MapOptions = {
    streetViewControl: false,
    panControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyC2tRmOGKUvJIAvpQP8YQyIEY3le2Y0eKI">
      <Map
        mapContainerStyle={containerStyle}
        options={GoogleMapsOptions}
        center={{ lat, lng: lon }}
        zoom={zoom}
      >
        {showMarker && markerPosition && (
          <Marker
            position={{ lat: markerPosition.lat, lng: markerPosition.lon }}
          />
        )}
      </Map>
    </LoadScript>
  )
}

export default GoogleMap
