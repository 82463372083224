import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

//componentns
import { createMarkupFromDocument } from 'components/RichText'
import { BackgroundColors, CaseHero } from 'types/contentful'

//styles
import './case-hero.scss'

interface Props {
  block: CaseHero
}

export const CaseHeroBlock: React.FC<Props> = ({ block }) => {
  const heroText = createMarkupFromDocument(block.body)
  const isFullScreenHero = !block.fullScreenHero
    ? 'casehero__image'
    : 'casehero__image-full'
  const titleStyle = block.fullScreenHero
    ? 'casehero__title hero--white'
    : 'casehero__title'
  const lineColor = block.fullScreenHero
    ? 'vertical-line white'
    : 'vertical-line normal'

  return (
    <div className="casehero">
      {block.heroImage?.gatsbyImageData && (
        <GatsbyImage
          image={block.heroImage?.gatsbyImageData}
          className={isFullScreenHero}
          alt=""
        />
      )}

      <div className={'casehero__content'}>
        <p className={titleStyle}>
          {block.client}
          <span className={lineColor} />
          {block.areaOfWork}
        </p>
        <h1 className="casehero__heroText">{heroText}</h1>
      </div>
    </div>
  )
}
