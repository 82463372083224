import React from 'react'
import { map, isEmpty } from 'lodash/fp'

// Styling
import './elements-two-columns.scss'

// Components
import { createMarkupFromDocument } from 'components/RichText'
import { Blob } from 'components/Blob'

// Types
import { BackgroundColors, Reference, BlockElement } from 'types/contentful'

interface Props {
  elements?: Reference[]
  background: BackgroundColors | undefined
  title: string
  children?: React.ReactNode | null
}

const WhichBlob = ['A', 'B', 'C']

interface TwoColElement {
  element: BlockElement
  which: 'A' | 'B' | 'C'
  text: React.ReactNode | null
}

export const TwoColElement: React.FC<TwoColElement> = ({
  element,
  which,
  text,
}) => {
  return (
    <div key={element.id} className="two-col-elements__box">
      {element.backgroundColor && element.featuredMedia == null && (
        <div className="two-col-elements__blob">
          <Blob which={which} color={element.backgroundColor} />
        </div>
      )}

      {!element.featuredMedia && (
        <h3 className="two-col-elements__title">{element.title}</h3>
      )}

      {element.featuredMedia != null && (
        <div className={'two-col-elements__image-container'}>
          <img
            className={'two-col-elements__image'}
            src={element.featuredMedia.file.url}
            alt="icon"
          />
        </div>
      )}

      {text && <div className="two-col-elements__content">{text}</div>}
    </div>
  )
}

export const ElementsTwoColumns: React.FC<Props> = ({
  elements,
  title,
  children,
}) => {
  if (isEmpty(elements)) {
    return null
  }

  let index = 0

  const rightColElements = elements?.filter((v, i) => i % 2 === 1)
  const leftColElements = elements?.filter((v, i) => i % 2 === 0)

  return (
    <div className="block block--two-col two-col-elements">
      <div className="two-col-elements__text">
        <h2 className="two-col-elements__header">{title}</h2>
        <div className="two-col-elements__preamble">{children}</div>
      </div>
      <div className="two-col-elements__container">
        <div className="two-col-elements__left">
          {map((element: BlockElement) => {
            const which = WhichBlob[index] as 'A' | 'B' | 'C'
            const text = element.body
              ? createMarkupFromDocument(element.body)
              : null

            index++

            return (
              <TwoColElement
                key={element.id}
                element={element}
                which={which}
                text={text}
              />
            )
          }, leftColElements)}
        </div>
        <div className="two-col-elements__right">
          {map((element: BlockElement) => {
            const which = WhichBlob[index] as 'A' | 'B' | 'C'
            const text = element.body
              ? createMarkupFromDocument(element.body)
              : null

            index++

            return (
              <TwoColElement
                key={element.id}
                element={element}
                which={which}
                text={text}
              />
            )
          }, rightColElements)}
        </div>
      </div>
    </div>
  )
}
