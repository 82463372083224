import React from 'react'

// Styling
import './partners.scss'

// Types
import { Reference } from 'types/contentful'

interface Props {
  elements: Reference[]
}

interface PartnersProps {
  cmsCommerce: Reference[]
  personalisationData: Reference[]
  digitalMarketingSoMe: Reference[]
  experienceCloud: Reference[]
}

export const Partners: React.FC<Props> = ({ elements }) => {
  if (!elements) return null
  const originalArray = [...elements]

  function splitArrays(originalArray: Reference[]) {
    const cmsCommerce: Reference[] = []
    const personalisationData: Reference[] = []
    const digitalMarketingSoMe: Reference[] = []
    const experienceCloud: Reference[] = []

    originalArray.forEach((item) => {
      if (item.category.includes('CMS & Commerce')) {
        cmsCommerce.push(item)
      } else if (item.category.includes('Personalisering & data')) {
        personalisationData.push(item)
      } else if (item.category.includes('Digital marknadsföring')) {
        digitalMarketingSoMe.push(item)
      } else {
        experienceCloud.push(item)
      }
    })

    const obj: PartnersProps = {
      cmsCommerce: cmsCommerce,
      personalisationData: personalisationData,
      digitalMarketingSoMe: digitalMarketingSoMe,
      experienceCloud: experienceCloud,
    }
    return obj
  }

  const allPartners = splitArrays(originalArray)

  return (
    <div className="partnersection" key="partnersection">
      <div className="partnersection__content">
        <h2>CMS & Commerce</h2>
        <div className="partneritems">
          {allPartners.cmsCommerce.map((ref) => {
            const isInternal = ref.link.startsWith('https://camelonta.se')
            const onClick = (event: any) => {
              if (!isInternal) {
                event.preventDefault()
                window.open(ref.link, '_blank')
              }
            }
            return (
              <a
                href={ref.link}
                key={ref.id}
                onClick={onClick}
                target={isInternal ? null : '_blank'}
                rel={isInternal ? null : 'noopener noreferrer'}
              >
                <div className="partneritems__boxcontainer">
                  <img src={ref.media.file.url} />
                </div>
              </a>
            )
          })}
        </div>

        <svg
          className="yellow-blob"
          width="146"
          height="141"
          viewBox="0 0 146 141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M145.5 81.7476C145.5 118.474 109.436 140.5 70.5 140.5C19 140.5 0 110.727 0 74C0 37.2731 40.5639 0 79.5 0C118.436 0 145.5 45.0206 145.5 81.7476Z"
            fill="#FFF9BA"
            fillOpacity={0.748142}
          />
        </svg>
        <svg
          className="purple-blob"
          width="317"
          height="300"
          viewBox="0 0 317 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M290.062 226.684C245.723 297.641 154.536 320.643 86.3887 278.06C18.2417 235.477 -25.839 145.957 18.5 75C62.8389 4.04285 178.807 -21.4813 246.954 21.1016C324.998 78.999 334.401 155.727 290.062 226.684Z"
            fill="#DFE3FF"
            fillOpacity={0.645733}
          />
        </svg>
        <h2>Personalisering & data</h2>
        <div className="partneritems">
          {allPartners.personalisationData.map((ref) => {
            const isInternal = ref.link.startsWith('https://camelonta.se')
            const onClick = (event: any) => {
              if (!isInternal) {
                event.preventDefault()
                window.open(ref.link, '_blank')
              }
            }
            return (
              <a
                href={ref.link}
                key={ref.id}
                onClick={onClick}
                target={isInternal ? null : '_blank'}
                rel={isInternal ? null : 'noopener noreferrer'}
              >
                <div className="partneritems__boxcontainer">
                  <img src={ref.media.file.url} />
                </div>
              </a>
            )
          })}
        </div>
        <h2>Digital marknadsföring & sociala medier</h2>
        <div className="partneritems">
          {allPartners.digitalMarketingSoMe.map((ref) => {
            const isInternal = ref.link.startsWith('https://camelonta.se')
            const onClick = (event: any) => {
              if (!isInternal) {
                event.preventDefault()
                window.open(ref.link, '_blank')
              }
            }
            return (
              <a
                href={ref.link}
                key={ref.id}
                onClick={onClick}
                target={isInternal ? null : '_blank'}
                rel={isInternal ? null : 'noopener noreferrer'}
              >
                <div className="partneritems__boxcontainer">
                  <img src={ref.media.file.url} />
                </div>
              </a>
            )
          })}
        </div>
        <h2>Experience & Cloud</h2>
        <div className="partneritems">
          {allPartners.experienceCloud.map((ref) => {
            const isInternal = ref.link.startsWith('https://camelonta.se')
            const onClick = (event: any) => {
              if (!isInternal) {
                event.preventDefault()
                window.open(ref.link, '_blank')
              }
            }
            return (
              <a
                href={ref.link}
                key={ref.id}
                onClick={onClick}
                target={isInternal ? null : '_blank'}
                rel={isInternal ? null : 'noopener noreferrer'}
              >
                <div className="partneritems__boxcontainer">
                  <img src={ref.media.file.url} />
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
