import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

//styles
import './case-image-fullscreen.scss'

import { CaseImageFullScreen } from 'types/contentful'

interface Props {
  block: CaseImageFullScreen
}

export const CaseImageFull: React.FC<Props> = ({ block }) => {
  return (
    <div className="imageFullscreen">
      {block.imageFullscreen?.gatsbyImageData && (
        <GatsbyImage
          image={block.imageFullscreen?.gatsbyImageData}
          className="imageFullscreen__image"
          alt=""
          objectFit="cover"
          objectPosition="center"
        />
      )}
    </div>
  )
}
