import React from 'react'
import { isEmpty, map, get } from 'lodash/fp'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

// Styling
import './content-references.scss'

// Types
import { BackgroundColors, Reference, ContentfulMedia } from 'types/contentful'

// Utils
import { convertPageTreeToUrl } from 'utils/pages'

interface Props {
  background: BackgroundColors | undefined
  references?: Reference[]
}

interface ReferenceProps {
  title: string
  content?: string
  media?: ContentfulMedia
  url: string
}

export const ContentReference: React.FC<ReferenceProps> = ({
  title,
  content,
  media,
  url,
}) => {
  return (
    <div className="content-ref">
      <div className={'content-ref__inner'}>
        <h3 className="content-ref__title">{title}</h3>
        <div className={'content-ref__content'}>
          {content && <p className="content-ref__text">{content}</p>}
          <Link
            to={`/${url}`}
            title={`Läs om ${title.toLowerCase()}`}
            className={'content-ref__link'}
          >{`Läs om ${title.toLowerCase()}`}</Link>
        </div>
      </div>
      {media && media.gatsbyImageData && (
        <div className="content-ref__media-container">
          <GatsbyImage
            image={media.gatsbyImageData}
            className="content-ref__media"
            alt=""
          />
        </div>
      )}
    </div>
  )
}

export const ContentReferences: React.FC<Props> = ({
  background,
  references,
}) => {
  if (isEmpty(references)) {
    return null
  }

  const style = {
    backgroundColor: background,
  }

  return (
    <div
      className={'block block--content-refs content-refs header-dark'}
      style={style}
    >
      <div className="content-refs__list">
        {map((ref) => {
          const url = convertPageTreeToUrl<Reference>(ref)
          return (
            <ContentReference
              key={ref.id}
              title={ref.title}
              url={url}
              content={get('excerpt.excerpt', ref)}
              media={get('featuredImage', ref)}
            />
          )
        }, references)}
      </div>
    </div>
  )
}
