import React, { FC } from 'react'

import './divider.scss'

const Divider: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1679"
      height="290"
      viewBox="0 0 1679 290"
      className="divider"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeDasharray="0 13"
        strokeLinecap="round"
      >
        <g stroke="#AB523C" strokeWidth="3">
          <path
            d="M-11.547 3907.15c25.041 34.113 62.836 58.984 113.383 74.612 50.548 15.628 110.449 8.027 179.704-22.805 88.896-44.337 167.772-41.306 236.628 9.094 103.285 75.6 195.635 19.011 230.682-19.652 35.046-38.663 144.628-196.06 278.332 5.928 133.705 201.987 268.556 20.588 419.203 7.408 100.43-8.787 183.888 35.898 250.373 134.054"
            transform="translate(-1 -3855) rotate(4 842.605 3974.64)"
          />
        </g>
      </g>
    </svg>
  )
}
export default Divider
