import React from 'react'
import { map, isEmpty, get } from 'lodash/fp'
import { Link } from 'gatsby'

/// Styling
import './subpage-boxes.scss'

// Types
import { Reference, BackgroundColors } from 'types/contentful'

// Utils
import { convertPageTreeToUrl } from 'utils/pages'

// Components
import { Blob, BlobColors } from 'components/Blob'

interface Props {
  references?: Reference[]
  title: string
  background: BackgroundColors | undefined
  excerpt: React.ReactNode | React.ReactNode[]
}

let color = '' as BlobColors

export const SubpageBoxesModule: React.FC<Props> = ({
  references,
  title,
  background,
  excerpt,
}) => {
  if (isEmpty(references)) {
    return null
  }

  if (background === '#f7bec8') {
    color = 'Pink' as BlobColors
  } else if (background === '#e5f6f7') {
    color = 'Blue' as BlobColors
  } else if (background === '#fffab2') {
    color = 'Yellow' as BlobColors
  }

  const textStyling =
    background != undefined
      ? 'subpage-boxes__preamble blob--visible'
      : 'subpage-boxes__preamble'

  if (
    references &&
    references.every(
      (r) => r.__typename == 'ContentfulSubpagesBoxItem' && !r.active
    )
  ) {
    return null
  }

  return (
    <div className="block block--subpage-boxes">
      <div className="subpage-boxes__text">
        {background != undefined && (
          <div className="subpage-boxes__blob">
            <Blob which={'C'} color={color} />
          </div>
        )}

        <h2 className="subpage-boxes__header">{title}</h2>
        <div className={textStyling}>{excerpt}</div>
      </div>
      <div className="subpage-boxes__list">
        {map((sb) => {
          if (sb.__typename === 'ContentfulSubpagesBoxItem') {
            if (!sb.active) {
              return null
            }
            const url = convertPageTreeToUrl(sb.page)
            const excerpt = get('page.excerpt.excerpt', sb)
            const img = get('image.fluid.src', sb)
            const title = get('page.title', sb)
            const bgImg = get('backgroundImage.fluid.src', sb)
            const textColor = get('textColor', sb)
            return (
              <Link
                key={sb.page.id}
                className="subpage-box"
                to={`/${url}`}
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `url(${bgImg})`,
                  color: `${textColor}`,
                }}
              >
                <div className="subpage-box__inner">
                  <img className="subpage-box__image" src={img} />
                  <h2 className="subpage-box__title">{title}</h2>
                  <p className="subpage-box__excerpt">{excerpt}</p>
                </div>
              </Link>
            )
          } else {
            const url = convertPageTreeToUrl(sb)
            const excerpt = get('excerpt.excerpt', sb)
            return (
              <Link key={sb.id} className="subpage-box" to={`/${url}`}>
                <div className="subpage-box__inner">
                  <h2 className="subpage-box__title">{sb.title}</h2>
                  <p className="subpage-box__excerpt">{excerpt}</p>
                </div>
              </Link>
            )
          }
        }, references)}
      </div>
    </div>
  )
}
