import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Styling
import './coworker.scss'

// Types
import { ContentfulCoworker } from 'types/contentful'

interface Props {
  coworker: Partial<ContentfulCoworker>
  clipPath?: string
  displayEmail?: boolean
}

export const Coworker: React.FC<Props> = ({
  coworker,
  displayEmail,
  clipPath,
}) => {
  const avatarStyle = {
    clipPath: `url(${clipPath})`,
    WebkitClipPath: `url(${clipPath})`,
    MozClipPath: `url(${clipPath})`,
    MSClipPath: `url(${clipPath})`,
  }
  return (
    <div className="card card--coworker coworker">
      {coworker?.avatar?.gatsbyImageData && (
        <GatsbyImage
          image={coworker?.avatar?.gatsbyImageData}
          alt={`Bild på ${coworker.fullName}`}
          className={'coworker__avatar'}
          style={avatarStyle}
        />
      )}
      {coworker.workTitle && (
        <span className="coworker__title">{coworker.workTitle}</span>
      )}
      {coworker.fullName && (
        <h3 className="coworker__name">{coworker.fullName}</h3>
      )}
      {coworker.email && displayEmail && (
        <span className="coworker__email">{coworker.email}</span>
      )}
    </div>
  )
}

Coworker.defaultProps = {
  displayEmail: false,
}
